<template>
  <div
    class="stds-dialog items-start pointer-events-auto w-full bg-[var(--stds-sem-color-background-variant-1)] overflow-auto"
  >
    <div
      class="relative top-[10rem] mx-auto w-[48rem] rounded-[4rem] bg-surface-elevation-1 p-40 shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)] after:pointer-events-none after:absolute after:-bottom-[3rem] after:right-[-11.8rem] after:h-[20rem] after:w-[21.2rem] after:bg-[url('@/assets/images/common/login-img-02.svg')] after:bg-cover"
    >
      <h2 class="flex justify-center gap-8">
        <img src="@/assets/images/common/gnb_stove_logo.svg" alt="" class="h-32" />
        <img src="@/assets/images/common/gnb_studio_logo.svg" alt="" class="h-32" />
      </h2>
      <p class="mt-16 text-center text-md leading-lg text-on-surface-elevation-3">
        <safe-html
          :html="`${
            registrationType === RegistrationType.email
              ? $t('studio.registration.agreement-required')
              : $t('login.studio-login-contents')
          }`"
        />
      </p>

      <div class="mt-[4.6rem] flex flex-col gap-4">
        <p class="text-on-surface-elevation-2 text-md leading-lg font-bold">
          {{ $t('studio.registration.email') }}
        </p>

        <div v-if="registrationType === RegistrationType.socialAccount">
          <input-text
            v-model="memberEmail"
            size="lg"
            :inputClass="`${isEmailInputDisabled === false ? 'pr-8' : ''}`"
            containerClass="w-full"
            variant="outline"
            name="email"
            :countable="true"
            :clearable="false"
            :placeholder="$t('studio.registration.email_place_holder')"
            maxLength="100"
            :disabled="isEmailInputDisabled"
            :rules="{
              max_length: {
                length: 100,
                message: $t('studio.common.def_key.exceed', { length: 100 })
              },
              email: $t('studio.registration.email_invalid_msg')
            }"
            :allowInputMaxLength="false"
          >
            <template v-if="!isRegistrationCompleted" #right>
              <button
                type="button"
                class="inline-flex shrink-0 text-xs font-medium leading-xs ml-8"
                :disabled="isSendCodeButtonDisabled"
                :class="{
                  [DISABLED_TEXT_COLOR_CLASS]: isSendCodeButtonDisabled,
                  'text-brand-primary': !isSendCodeButtonDisabled
                }"
                @click="sendVerifyCodeToMemberSecondaryEmail"
              >
                {{ sendCodeBtnTextRef }}
              </button>
            </template>
          </input-text>
          <template v-if="isCodeSentRef">
            <input-text
              v-model="authenticationCodeRef"
              size="lg"
              containerClass="mt-8 w-full"
              variant="outline"
              name="passCode"
              :countable="false"
              :clearable="false"
              :disabled="isAuthenticationCodeInputDisabled"
              :placeholder="$t('studio.registration.verification_code_place_holder')"
              @input="enableAuthenticationCodeBtn"
            >
              <template #right>
                <span
                  v-if="isShowCountDownTimerRef"
                  class="ml-8 inline-flex shrink-0 text-md font-medium leading-sm"
                  :class="ERROR_COLOR"
                >
                  {{ formattedCountDownTimerRef }}
                </span>
                <span
                  v-if="!isShowAuthenticationCodeBtnRef"
                  class="ml-8 inline-flex shrink-0 text-md font-medium leading-sm"
                  :class="[
                    {
                      [DISABLED_TEXT_COLOR_CLASS]: isAuthenticationCodeInputDisabled,
                      [authenticationCodeTextColorRef]: !isAuthenticationCodeInputDisabled
                    }
                  ]"
                >
                  {{ authenticationCodeMessageRef }}
                </span>
                <button
                  v-if="isShowAuthenticationCodeBtnRef"
                  type="button"
                  class="inline-flex shrink-0 text-xs font-medium leading-xs ml-8"
                  :disabled="isAuthenticationCodeButtonDisabled"
                  :class="{
                    [DISABLED_TEXT_COLOR_CLASS]: isAuthenticationCodeButtonDisabled,
                    'text-brand-primary': !isAuthenticationCodeButtonDisabled
                  }"
                  @click="verifyCodeAndRegisterMemberSecondaryEmail"
                >
                  <safe-html :html="$t('studio.verification_code.check_btn')" />
                </button>
              </template>
            </input-text>
            <p class="text-sm leading-regular mt-4" :class="[DISABLED_TEXT_COLOR_CLASS]">
              {{ $t('studio.registration.verification_code_not_received_guide') }}
            </p>
          </template>
        </div>
        <input-text
          v-else
          v-model="memberEmail"
          size="lg"
          variant="outline"
          name="email"
          :disabled="true"
        />
        <p class="mt-24 text-md font-bold leading-lg text-on-surface-elevation-2">
          {{ $t('studio.registration.terms-privacy-agree') }}
        </p>
        <div class="flex flex-col rounded-3xl bg-[var(--stds-glob-color-gray40)] py-12 px-20">
          <div class="studio-checkbox-container py-8">
            <input
              id="chk-all"
              v-model="checkAllRef"
              type="checkbox"
              :disabled="termIdsRef.length === 0"
              class="studio-checkbox peer !my-4"
              @change="onSelectAllTerms"
            />
            <label for="chk-all" class="studio-checkbox-label !text-lg font-bold !leading-lg">
              {{ $t('studio.registration.all-agree') }}
            </label>
          </div>
          <div
            class="mt-8 flex flex-col gap-16 border-t-1 border-solid border-inverse-elevation-5 pt-8"
          >
            <div v-for="term of termsRef" :key="term.contentsNo" class="studio-checkbox-container">
              <input
                :id="term.contentsNo.toString()"
                v-model="agreeTerms"
                type="checkbox"
                :value="term.contentsNo"
                name="agreeTerms"
                class="studio-checkbox peer"
                @change="(e) => onSelectTerm(e, term.agreeType)"
              />
              <label :for="term.contentsNo.toString()" class="studio-checkbox-label">
                {{ term.title }}
              </label>
              <button
                type="button"
                class="my-2 ml-auto shrink-0 self-start text-xs font-medium leading-xs text-on-surface-elevation-2"
                @click="() => viewTerm(term.subCategory, term.contentsNo)"
              >
                {{ $t('studio.registration.view-terms') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <s-button
        variant="primary"
        size="md"
        class="w-full mt-40"
        :disabled="isRegisterButtonDisabled"
        @click="registerMemberByEmail"
      >
        {{ $t('studio.registration.sign_up_btn') }}
      </s-button>

      <s-button
        variant="white"
        size="sm"
        class="*:text-md *:font-regular *:text-on-surface-elevation-3 mt-16 w-full"
        @click="redirectToLoginPage"
      >
        {{ $t('studio.registration.redirect-login-page') }}
      </s-button>
      <div class="relative w-full flex justify-center">
        <dropdown
          v-model="currentLanguageRef"
          :options="languageOptions"
          :dropdownProps="{
            size: 'sm',
            variant: 'line',
            distance: 0,
            offset: [0, 0],
            placement: 'bottom-start',
            class: 'w-full',
            itemDropdownClass: 'w-full'
          }"
          containerClass="w-[18rem] mt-16 relative"
          closeOnClick
          @update:modelValue="handleChangeLanguage"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { email as isEmail } from '@vee-validate/rules';
import { storeToRefs } from 'pinia';
import { type GenericObject, useForm } from 'vee-validate';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  getMemberSecondaryEmailApi,
  registerMemberSecondaryEmailBySessionApi,
  sendVerifyCodeToMemberSecondaryEmailApi,
  signUpMemberApi,
  verifyCodeFromMemberSecondaryEmailApi
} from '@/apis/member.api';
import {
  agreeWithSubscriptionTermsAndConditionApi,
  fetchSubscriptionTermsAndConditionApi
} from '@/apis/terms.api';
import safeHtml from '@/components/common/safe-html.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm } from '@/composables/useDialog';
import {
  DEFAULT_NATION,
  PLATFORM,
  SERVICE_ID,
  TRANSLATE_LANGUAGES,
  VIEW_AREA
} from '@/constants/common.const';
import { GROUP_REGISTER_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { TermAgreeType, TermsTypeId } from '@/enums/terms.enum';
import { useAppStore } from '@/stores/app.store';
import { useTermsStore } from '@/stores/terms.store';
import { useUserStore } from '@/stores/user.store';
import type { LanguageModel } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type {
  ContentModel,
  ServiceInfoModel,
  ShortContentModel
} from '@/types/terms/terms-model.type';
import { redirectTo } from '@/utils/common.util';
import { goToLoginPage, logout } from '@/utils/user.utils';
import { definePageMeta } from '#imports';

enum RegistrationType {
  email = 'email',
  socialAccount = 'social-account'
}

interface StudioRegister {
  email: string;
  agreeTerms: Array<number>;
  passCode?: string;
}
const { t, locale } = useI18n();
const userStore = useUserStore();
const app = useApp();
const { gdsInfo } = storeToRefs(useAppStore());
const { userInfo, setMemberOrNot } = userStore;
definePageMeta({
  layout: 'empty',
  middleware: ['check-login-middleware', 'check-user-age-middleware', 'redirect-middleware']
});

const termsStore = useTermsStore();
const { setIsNotAgreeAllTerms, fetchAgreedTerms } = termsStore;
const { handleSubmit, defineField, setFieldValue, errors } = useForm<StudioRegister>({
  initialValues: {
    email: '',
    passCode: '',
    agreeTerms: []
  }
});
const AUTHENTICATION_FAILED = t('studio.registration.verification_stt_failed');
const CERTIFICATION_EXPIRATION = t('studio.registration.verification_stt_code_expired');
const AUTHENTICATION_COMPLETED = t('studio.registration.verification_stt_complete');
const RESEND_CODE_TEXT = t('studio.registration.resend_verification_code_btn');
const SEND_CODE_TEXT = t('studio.registration.send_verification_code_btn');
const ERROR_COLOR = 'text-tint-red-a400';
const NORMAL_COLOR = 'text-brand-primary';
const [memberEmail]: [Ref<string>, GenericObject] = defineField('email');
const [agreeTerms]: [Ref<string>, GenericObject] = defineField('agreeTerms');
const termsRef = ref<ShortContentModel[]>([]);
const currentLanguageRef = ref<string>(locale.value);
const checkAllRef = ref(false);
const authenticationCodeRef = ref('');
const formattedCountDownTimerRef = ref<string>('');
const authenticationCodeMessageRef = ref<string>('');
const sessionRef = ref<string>('');
const authenticationCodeInputVisibilityRef = ref<boolean>(false);
const isShowAuthenticationCodeBtnRef = ref<boolean>(true);
const isShowCountDownTimerRef = ref<boolean>(false);
const authenticationCodeTextColorRef = ref<string>(ERROR_COLOR);
const sendCodeBtnTextRef = ref<string>(SEND_CODE_TEXT);
const registrationType = ref<RegistrationType>(RegistrationType.email);
const isRegistrationCompleted = ref<boolean>(false);
const isEmailInputDisabled = ref<boolean>(false);
const isCodeSentRef = ref<boolean>(false);
const termIdsRef = ref<Array<number>>([]);
let authenticationCodeExpiresIn = 0;
let numOfRequiredTerms = 0;
const numOfSelectedRequiredTermsRef = ref<number>(0);
const isRegisterButtonDisabled = computed(() => {
  if (
    termIdsRef.value.length === 0 ||
    isRegistrationCompleted.value === false ||
    numOfSelectedRequiredTermsRef.value < numOfRequiredTerms
  ) {
    return true;
  }
  return false;
});
const isSendCodeButtonDisabled = computed(() => {
  if (
    isRegistrationCompleted.value ||
    memberEmail.value.length === 0 ||
    errors.value.email !== undefined
  ) {
    return true;
  }
  return false;
});

const isAuthenticationCodeInputDisabled = computed(() => {
  if (isRegistrationCompleted.value || !authenticationCodeInputVisibilityRef.value) {
    return true;
  }
  return false;
});

const isAuthenticationCodeButtonDisabled = computed(() => {
  if (isAuthenticationCodeInputDisabled.value || authenticationCodeRef.value.length === 0) {
    return true;
  }
  return false;
});
const languageOptions: Array<FormOption> = TRANSLATE_LANGUAGES.map((language: LanguageModel) => {
  return {
    label: language.langTitle,
    value: language.langCode
  };
});
let timer: ReturnType<typeof setInterval> | undefined;
const DISABLED_TEXT_COLOR_CLASS = 'text-[#9FA6AA]';

const setUpTermsAndCondition = async () => {
  const infos = await fetchSubscriptionTermsAndConditionApi({
    serviceId: SERVICE_ID.INDIEGAME,
    viewareaId: VIEW_AREA.STDJOIN,
    nation: gdsInfo.value.nation,
    lang: locale.value,
    textYn: Confirmation.YES,
    textFormat: PLATFORM.PC
  });
  if (!infos || !infos.serviceInfos || !infos.serviceInfos.length) {
    return;
  }
  const serviceInfo = infos.serviceInfos.find(
    (service: ServiceInfoModel) => service.serviceId === SERVICE_ID.INDIEGAME
  );
  if (serviceInfo && serviceInfo.contentsList.length) {
    const excludedTermsTypeIdList = getExcludedTermTypeIdsByNation(gdsInfo.value.nation);
    let termList: Array<ContentModel> = [...serviceInfo.contentsList];
    excludedTermsTypeIdList.forEach((termsTypeId: string) => {
      termList = termList.filter((content: ContentModel) => content.termsTypeId !== termsTypeId);
    });
    termsRef.value = termList.map((content: ContentModel) => {
      if (
        content.agreeType === TermAgreeType.ALWAYS_MUST ||
        content.agreeType === TermAgreeType.FIRST_MUST
      ) {
        numOfRequiredTerms++;
      }
      return {
        name: content.name,
        title: content.title,
        agreeType: content.agreeType as TermAgreeType,
        contentsNo: content.contentsNo,
        versionNo: content.versionNo,
        versionId: content.versionId,
        subCategory: content.subCategory,
        termsTypeId: content.termsTypeId
      };
    });
    const studioJoinTermsTypeId = getStudioJoinTermTypIdeByNation(gdsInfo.value.nation);
    termsRef.value.sort((a: ShortContentModel) =>
      a.termsTypeId === studioJoinTermsTypeId ? -1 : 1
    );
    termIdsRef.value = termsRef.value.map((term: ShortContentModel) => {
      return term.contentsNo;
    });
  }
};

function setFormattedCountDownTimer(value: string) {
  formattedCountDownTimerRef.value = value;
}

function setCountDownTimerVisibility(visibility: boolean) {
  isShowCountDownTimerRef.value = visibility;
}

function enableAuthenticationCodeBtn() {
  if (!isShowAuthenticationCodeBtnRef.value) {
    // startAuthenticationCodeCountDown();
    setAuthenticationCodeBtnVisibility(true);
  }
}

function setAuthenticationCodeBtnVisibility(visibility: boolean) {
  isShowAuthenticationCodeBtnRef.value = visibility;
}

function getStudioJoinTermTypIdeByNation(nation: string) {
  return nation === DEFAULT_NATION
    ? TermsTypeId.INDIEGAMESTUDIOJOIN
    : TermsTypeId.INDIEGAMESTUDIOJOIN_EN;
}

function getExcludedTermTypeIdsByNation(nation: string): string[] {
  const list = [];
  if (nation === DEFAULT_NATION) {
    list.push(TermsTypeId.INDIEGAMESTUDIOJOIN_EN);
  } else {
    list.push(TermsTypeId.INDIEGAMESTUDIOJOIN);
  }
  return list;
}

function handleChangeLanguage(language: string | number) {
  app.changeLocale(language as string);
}

async function setUpMemberEmail() {
  const email = userInfo?.userId;
  if (email && isEmail(email)) {
    isRegistrationCompleted.value = true;
    setMemberEmail(email);
  } else {
    const data = await getMemberSecondaryEmailApi();
    if (data && data.email) {
      setMemberEmail(data.email);
      isRegistrationCompleted.value = true;
    } else {
      setRegistrationType(RegistrationType.socialAccount);
    }
  }
}

function onSelectTerm(e: Event, agreeType: TermAgreeType) {
  const target = e.target as HTMLInputElement;
  if (target.checked && agreeTerms.value.length === termIdsRef.value.length) {
    checkAllRef.value = true;
  } else {
    checkAllRef.value = false;
  }
  if (agreeType === TermAgreeType.ALWAYS_MUST || agreeType === TermAgreeType.FIRST_MUST) {
    if (target.checked) {
      numOfSelectedRequiredTermsRef.value++;
    } else {
      numOfSelectedRequiredTermsRef.value--;
    }
  }
}
function onSelectAllTerms(e: Event) {
  const target = e.target as HTMLInputElement;
  let selectedTerms: Array<number> = [];
  if (target.checked) {
    selectedTerms = termIdsRef.value;
    numOfSelectedRequiredTermsRef.value = numOfRequiredTerms;
  } else {
    numOfSelectedRequiredTermsRef.value = 0;
  }
  setFieldValue('agreeTerms', selectedTerms);
}

function viewTerm(subCategory: string, contentsNo: number) {
  const host = window.location.origin;
  window.open(
    `${host}/${locale.value}/terms/detail?subCategory=${subCategory}&contentsNo=${contentsNo}`,
    '_blank'
  );
}

async function redirectToLoginPage() {
  const result = await showConfirm({
    content: t('studio.registration.cancel-confirm'),
    confirmLabel: t('studio.registration.do-later'),
    cancelLabel: t('studio.registration.close'),
    cancelVariant: 'outline'
  });
  if (result) {
    logout();
    goToLoginPage('');
  }
}

function convertSecondsToTimeString(countDownTime: number) {
  const dateObj = new Date(countDownTime * 1000);
  const hours = dateObj.getUTCHours();
  let minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();
  let pad = 2;
  if (hours > 0) {
    minutes += hours * 60;
  }

  if (minutes >= 100) {
    pad = 3;
  }
  const timeString =
    minutes.toString().padStart(pad, '0') + ':' + seconds.toString().padStart(2, '0');
  return timeString;
}
function setRegistrationType(type: RegistrationType) {
  registrationType.value = type;
}
function setMemberEmail(email: string) {
  setFieldValue('email', email);
}
function setAuthenticationCodeMessage(message: string) {
  authenticationCodeMessageRef.value = message;
}
function setAuthenticationCodeButtonTextColor(colorClass: string) {
  authenticationCodeTextColorRef.value = colorClass;
}
function setSendCodeButtonText(text: string) {
  sendCodeBtnTextRef.value = text;
}
function setAuthenticationCodeInputVisibility(isEnabled: boolean) {
  authenticationCodeInputVisibilityRef.value = isEnabled;
}

function setAuthenticationCode(code: string) {
  authenticationCodeRef.value = code;
}
function setEmailInputVisibility(isDisabled: boolean) {
  isEmailInputDisabled.value = isDisabled;
}

function startVerificationCodeCountDown() {
  const seconds = Math.ceil((authenticationCodeExpiresIn - Date.now()) / 1000);
  doVerificationCodeCountDown(seconds);
}
function doVerificationCodeCountDown(seconds: number) {
  if (seconds > 0) {
    setFormattedCountDownTimer(convertSecondsToTimeString(seconds));
  }
  let remainTime = seconds;
  timer = setInterval(() => {
    if (remainTime > 0) {
      const timeInString = convertSecondsToTimeString(--remainTime);
      setFormattedCountDownTimer(timeInString);
    } else {
      clearInterval(timer);
      setCountDownTimerVisibility(false);
      setAuthenticationCodeBtnVisibility(false);
      setAuthenticationCodeMessage(CERTIFICATION_EXPIRATION);
    }
  }, 1000);
  setCountDownTimerVisibility(true);
}
function stopAuthenticationCodeCountDown() {
  if (timer) {
    clearInterval(timer);
    timer = undefined;
    setFormattedCountDownTimer('');
  }
}

async function verifyCodeAndRegisterMemberSecondaryEmail() {
  if (authenticationCodeRef.value && sessionRef.value) {
    stopAuthenticationCodeCountDown();
    const data = await verifyCodeFromMemberSecondaryEmailApi({
      code: authenticationCodeRef.value,
      session: sessionRef.value
    });
    if (data && data.session) {
      setAuthenticationCodeMessage(AUTHENTICATION_COMPLETED);
      setAuthenticationCodeButtonTextColor(NORMAL_COLOR);
      const isRegistrationSuccessful = await registerMemberSecondaryEmailBySessionApi(data.session);
      if (isRegistrationSuccessful) {
        isRegistrationCompleted.value = true;
      }
      return;
    }
    setCountDownTimerVisibility(false);
    setAuthenticationCodeBtnVisibility(false);
    setAuthenticationCodeMessage(AUTHENTICATION_FAILED);
  }
}
async function registerMemberByEmail() {
  try {
    if (memberEmail.value) {
      const result = await agreeWithSubscriptionTermsAndConditionApi({
        serviceInfos: [
          {
            serviceId: SERVICE_ID.INDIEGAME
          }
        ],
        viewareaId: VIEW_AREA.STDJOIN,
        nation: gdsInfo.value.nation
      });
      if (!result) {
        return;
      }
      setIsNotAgreeAllTerms(false);
      await signUpMemberApi(memberEmail.value);
      setMemberOrNot(true);
      await fetchAgreedTerms();
      redirectTo(GROUP_REGISTER_PAGE_URL);
    }
  } catch (error) {
    // console.log('error: ', error);
  }
}
const sendVerifyCodeToMemberSecondaryEmail = handleSubmit(async () => {
  stopAuthenticationCodeCountDown();
  setAuthenticationCodeInputVisibility(false);
  setAuthenticationCode('');
  const data = await sendVerifyCodeToMemberSecondaryEmailApi(memberEmail.value);
  if (data && data.session && !isNaN(data.expiresIn)) {
    sessionRef.value = data.session;
    isCodeSentRef.value = true;
    setSendCodeButtonText(RESEND_CODE_TEXT);
    setEmailInputVisibility(true);
    authenticationCodeExpiresIn = data.expiresIn;
    const content = t('studio.registration.send_verification_code_completed_popup');
    enableAuthenticationCodeBtn();
    startVerificationCodeCountDown();
    setAuthenticationCodeInputVisibility(true);
    showAlert({ content });
  }
});

async function init() {
  await setUpMemberEmail();
  await setUpTermsAndCondition();
}
init();
</script>
